<template>
  <div class="filter-wrapper">
    <div class="filter-container">
    <div v-if="isAuditor" class="actor-filter">
        <label for="actorSearch">Filter by Actor:</label>
        <input
          name="actorSearch"
          class="form-control"
          v-model="userVal"
          placeholder="*"
        />
      </div>
      <div class="verb-filter">
        <label for="verbSearch">Filter by Verb:</label>
        <input
          name="verbSearch"
          class="form-control"
          v-model="verbVal"
          placeholder="*"
        />
      </div>
      </div>
      <div class="d-flex" :class="pageNo > 0 ? 'justify-content-between': 'justify-content-end'">
          <div v-if="pageNo > 0" class="btn">
            <i
              class="custom-box-css d-flex box-icon box-icon--effect bx bx-chevrons-left"
              name="chevrons-left"
              title="Preivous Page"
              @click="previusPage"
            ></i>
          </div>
          <div class="btn">
              <i
                class="custom-box-css d-flex box-icon box-icon--effect bx bx-chevrons-right"
                name="chevrons-right"
                title="Next Page"
                @click="nextPage"
              ></i>
          </div>
      </div>
      <!-- <div class="value-filter">
        <div class="key">
          <label for="valueSearch">Value Key:</label>
          <input
            name="valueKey"
            class="form-control"
            v-model="filters.value.keys[0]"
            placeholder="*"
          />
        </div>
        <div class="value">
          <label for="valueSearch">Value Search:</label>
          <input
            name="valueSearch"
            class="form-control"
            v-model="filters.value.value"
            placeholder="*"
          />
        </div>
      </div> -->
    <!-- <div class="filter-settings">
      <label for="showWithJsonViewer"> Show formated JSON</label>
      <input
        type="checkbox"
        name="showWithJsonViewer"
        v-model="settings.showJson"
      />
    </div> -->
    <br />

    <!-- <v-table :data="eventsData" class="table table-striped" :filters="filters">
      <thead slot="head">
        <v-th sortKey="timestamp.machineReadable" defaultSort="desc"
          >Thought Jumper Events Notifications</v-th
        >
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>
            <span v-html="eventLogMessage(row.actor.username, row.message, row.timestamp.machineReadable)"></span>
          </td>
        </tr>
      </tbody>
    </v-table> -->
    <a-timeline>
      <a-timeline-item v-for="row in eventsData" :key="row.id" :color="activityColor(row.verb.display.en)">
        <span v-html="eventLogMessage(row.actor, row.message, row.timestamp.machineReadable)"></span>
      </a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'HistoryEvents',
  data() {
    return {
      userVal: this.$store.state.auth.user.username,
      verbVal: '',
      pageNo: 0,
      timer: null,
      // settings: {
      //   showJson: false,
      // },
    };
  },

  watch: {
    userVal() {
      this.callEvent(this.fetchEvents);
    },
    verbVal() {
      this.callEvent(this.fetchEvents);
    },
    pageNo() {
      console.log('pagenO');
      this.callEvent(this.fetchEvents);
    },
  },

  computed: {
    eventsData() {
      return this.$store.state.eventsRaw;
    },

    isAuditor() {
      return this.$store.state.auth.user.roles?.includes('auditor') || false;
    },
  },

  methods: {
    activityColor(verb) {
      switch (verb) {
        case 'NOTED':
          return 'blue';
        case 'VISITED':
          return 'orange';
        default:
          return 'green';
      }
    },
    async fetchEvents() {
      const payload = {
        pageNo: this.pageNo,
        username: this.userVal.trim(),
        verb: this.verbVal.trim(),
      };
      await this.$store.dispatch('eventsDumpFetch', payload);
    },

    nextPage() {
      this.pageNo += 1;
    },

    previusPage() {
      this.pageNo -= 1;
    },

    callEvent(callback) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        callback();
      }, 500);
    },

    timesAgo(date) {
      if (date) return moment(date).fromNow();
      return '';
    },
    eventLogMessage(actor, message, timestamp) {
      return `${actor ? actor.username : 'Unknown user'} ${message} ${this.timesAgo(timestamp)}`;
    },
  },

  sockets: {
    connect() {},
    newNotification(data) {
      const payload = {
        ...data,
      };
      // console.log('xvf', 'data', data);
      this.$store.dispatch('newEvent', payload);
    },
  },

  async mounted() {
    await this.fetchEvents();
  },
};
</script>

<style lang="scss" scoped>
.filter-wrapper {
  padding: 0.5em 1em;
}
table tr td:nth-child(4) {
  word-break: break-word;
}
th svg path {
  fill: #000;
}

.filter-container {
  display: flex;
  flex-direction: row;
  gap: 5em;
}

.filter-settings {
  display: flex;
  padding: 1em 0;
  align-items: center;
}
</style>
